import React, { useState } from "react";
import styles from "./css/ApprovalProcess.module.css";
import { MdClose } from "react-icons/md";
import { useAuthContext } from "../../context/AuthContext";
import useApprovalRequests from "../../hooks/useApprovalRequest";
import { useUIContext } from "../../context/UIContext";

/*생각해 보자.
props로 받아와야 할 것
document_forms table에서
schoolid, name으로 찾아서 
가져올 때 default_approver_group_id로 join 해서 가져오고
document_type: fixedFormApproval, 

keyData에서
username, fullname
학년도, 학기, 과정, 학년, 반 
제목 (합성)
발행일(종강일)을 가져오고 (변경 가능)


일자는 여기서 만들어내고
documnet_id, requester_id, + username, condition_type, condition_value 로 찾아서, 
approval_request id로 조인해서 온 approval_actions 정보로 결재단계별 결재여부를 나타낸다.

requests에 db가 없으면, 신규 결재로 하고

db가 있으면, 첫번째 결재 체크 상태로, 취소, 반려 같은 것을 나타낸다.

*/
export default function ApprovalProcess({
  isModalOpen,
  handleCloseModal,
  documentData,
  approvalData,
  requestType,
  requestTitle,
  studentListForApproval,
  documentFormData,
  approvalLines,
  handleExtraFunction = () => {},
}) {
  const { user } = useAuthContext();

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  const [canceledWell, setCanceledWell] = useState(false);
  const [approvedWell, setApprovedWell] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
  const [checkedStudents, setCheckedStudents] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [releaseDate, setReleaseDate] = useState(
    documentData?.currentPeriod?.end_date || ""
  );

  // const [approvalRequestFormat, setApprovalRequestFormat] = useState({
  //   schoolid: "",
  //   requester_username: "",
  //   document_id: "",
  //   title: requestTitle,
  //   status: "",
  //   stage: "",
  //   request_type: requestType,
  //   target_username: "",
  //   school_year: "",
  //   term_id: "",
  //   release_date: "",
  //   approval_lines: [],
  // });

  const approvalRequestConditions = {
    schoolid: approvalData?.schoolid,
    username: user?.username,
    document_id: documentFormData[0]?.id,
    request_type: requestType,
    term_id: documentData?.currentPeriod?.id,
  };

  const {
    approvalRequestsQuery: { data: approvalRequestData },
    createOrUpdateApprovalRequestsBulk,
  } = useApprovalRequests(approvalRequestConditions);

  /* 보류. 사용자 경험을 고려해서 
  useEffect(() => {
    // 모든 학생을 초기 checkedStudents 상태에 추가
    const allStudentUsernames = studentListForApproval.map(
      (student) => student.username
    );
    setCheckedStudents(allStudentUsernames);
  }, [studentListForApproval]);
*/

  // useEffect(() => {
  //   if (documentFormData && user) {
  //     const lines = [
  //       {
  //         username: user.username,
  //         fullname: user.fullname,
  //         stage: 1,
  //       },
  //       ...documentFormData.map((member) => ({
  //         username: member.approvalGroupMembers.username,
  //         fullname: member.approvalGroupMembers.user.fullname,
  //         stage: member.approvalGroupMembers.stage,
  //       })),
  //     ];

  //     setApprovalLines(lines);
  //   }
  // }, [documentFormData, user]);

  /* 로직을 정리해 보자.
 Validataion
 1. 이미 자신의 단계 이상이 결재 되어 있으면 결재도 취소도 할 수 없다.
 2. finalized가 되어 있으면 해당 stage를 가진 사람이 취소만 할 수 있다.
 3. approval_lines도 저장해야 한다. - user와 default group에서 가져온다.
 4. 
 */

  /*
  const handleApprove = () => {
    console.log("결재 승인");


    //체크 되어 있는 학생들
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = studentsToApprove.filter((student) => {
      const existingRequest = approvalRequestData.find(
        (request) =>
          request.target_username === student.username &&
          (request.stage > approverStage || request.status === "finalized")
      );

      if (existingRequest) {
        studentsToExclude.push(student.fullname);
        return false;
      }

      return true;
    });

    if (studentsToProcess.length === 0) {
      handleToastCenterTop("결재할 학생이 없습니다.");
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `다음 학생들은 이미 결재되었거나 상위 단계가 결재 중이어서 제외되었습니다:\n- ${studentsToExclude.join(
        ", "
      )}`;
      handleToastCenterTop(excludeMessage);
    }

    const requestsToSave = studentsToProcess.map((student) => ({
      schoolid: approvalData.schoolid,
      requester_username: approvalLines[0].username,
      document_id: documentFormData[0].id,
      title: requestTitle,
      status:
        approverStage === approvalLines.length ? "finalized" : "inProgress",
      stage:
        approverStage === approvalLines.length
          ? approverStage
          : approverStage + 1,
      request_type: requestType,
      target_username: student.username,
      school_year: documentData.school_year,
      term_id: documentData.currentPeriod.id,
      release_date: releaseDate,
      approval_lines: approvalLines,
    }));

    // console.log("documentData", documentData);
    console.log("requestsToSave", requestsToSave);
    // return;

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        handleToastCenterTop("결재가 성공적으로 처리되었습니다.");
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };
*/

  /* 
  const handleApprove = () => {
    // 체크되어 있는 학생들
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );
    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    // approvalRequestData가 없을 경우 대비
    const existingRequests = approvalRequestData || [];
    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록
    const requestsToCreate = []; // 생성할 요청 목록

    //로직을 써 보고 하나씩 맞춰보자.
    //1. 그냥, 내 결재 단계와 안 맞은 결재 못한다. 내가 stage 3이면, 이것도 3이어야 하고, 1이면, 새로 생성이다.

    studentsToApprove.forEach((student) => {
      const existingRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          (request.stage > approverStage || request.status === "finalized")
      );
      console.log("existingRequests", existingRequests);

      console.log("existingRequest", existingRequest);
      console.log("approverStage", approverStage);

      if (existingRequest) {
        if (existingRequest.stage < approverStage) {
          handleToastCenterTop("아직 하위 결재가 되지 않았습니다.");
          return;
        }
        studentsToExclude.push(student.fullname);

        console.log("studentsToExclude", studentsToExclude);
      } else {
        // console.log("approverStage", approverStage);

        if (approverStage !== 1) {
          // console.log("approverStage !== 1", approverStage !== 1);

          handleToastCenterTop("아직 하위 결재가 되지 않았습니다.");
          return;
        }
        const requestToUpdate = existingRequests.find(
          (request) => request.target_username === student.username
        );

        console.log("existingRequests", requestToUpdate);

        console.log("requestToUpdate", requestToUpdate);

        if (requestToUpdate) {
          requestToUpdate.status =
            approverStage === approvalLines.length ? "finalized" : "inProgress";
          requestToUpdate.stage = approverStage + 1;
          requestToUpdate.release_date = releaseDate;
          requestToUpdate.approver_stage = approverStage;
          requestToUpdate.approver_username = user.username;
          requestToUpdate.action = "approve";

          requestsToUpdate.push(requestToUpdate);
        } else {
          requestsToCreate.push({
            schoolid: approvalData.schoolid,
            requester_username: approvalLines[0].username,
            document_id: documentFormData[0].id,
            title: requestTitle,
            status:
              approverStage === approvalLines.length
                ? "finalized"
                : "inProgress",
            stage: approverStage + 1,
            request_type: requestType,
            target_username: student.username,
            school_year: documentData.school_year,
            term_id: documentData.currentPeriod.id,
            release_date: releaseDate,
            approval_lines: approvalLines,
            approver_stage: approverStage,
            approver_username: user.username,
            action: "approve",
          });
        }
        studentsToProcess.push(student.fullname); // 결재할 학생 목록에 추가
      }
    });
    console.log("requestToUpdate", requestsToUpdate);
    console.log("requestsToCreate", requestsToCreate);

    console.log("studentsToProcess", studentsToProcess);

    if (studentsToProcess.length === 0) {
      handleToastCenterTop("결재 단계가 맞거나 결재할 학생이 없습니다.");
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `다음 학생들은 결재 단계가 안 맞아서 제외되었습니다:\n- ${studentsToExclude.join(
        ", "
      )}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = [...requestsToCreate, ...requestsToUpdate];

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setApprovedWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setApprovedWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

*/

  const handleApprove = () => {
    // 체크되어 있는 학생들
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    // approvalRequestData가 없을 경우 대비
    const existingRequests = approvalRequestData || [];
    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록
    const requestsToCreate = []; // 생성할 요청 목록

    studentsToApprove.forEach((student) => {
      const existingRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          (request.stage > approverStage || request.status === "finalized")
      );

      const lowerStageNotApproved = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage < approverStage &&
          request.status !== "finalized"
      );

      if (existingRequest || lowerStageNotApproved) {
        studentsToExclude.push(student.fullname);
      } else {
        const requestToUpdate = existingRequests.find(
          (request) => request.target_username === student.username
        );

        if (requestToUpdate) {
          requestToUpdate.status =
            approverStage === approvalLines.length ? "finalized" : "inProgress";
          requestToUpdate.stage = approverStage + 1;
          requestToUpdate.release_date = releaseDate;
          requestToUpdate.approver_stage = approverStage;
          requestToUpdate.approver_username = user.username;
          requestToUpdate.action = "approve";

          requestsToUpdate.push(requestToUpdate);
        } else {
          requestsToCreate.push({
            schoolid: approvalData.schoolid,
            requester_username: approvalLines[0].username,
            document_id: documentFormData[0].id,
            title: requestTitle,
            status:
              approverStage === approvalLines.length
                ? "finalized"
                : "inProgress",
            stage: approverStage + 1,
            request_type: requestType,
            target_username: student.username,
            school_year: documentData.school_year,
            term_id: documentData.currentPeriod.id,
            release_date: releaseDate,
            approval_lines: approvalLines,
            approver_stage: approverStage,
            approver_username: user.username,
            action: "approve",
          });
        }
        studentsToProcess.push(student.fullname); // 결재할 학생 목록에 추가
      }
    });

    if (studentsToProcess.length === 0) {
      handleToastCenterTop("결재할 학생이 없습니다.");
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `다음 학생들은 결재 단계가 맞지 않아서 제외되었습니다:\n- ${studentsToExclude.join(
        ", "
      )}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = [...requestsToCreate, ...requestsToUpdate];

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setApprovedWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setApprovedWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleCancel = () => {
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    const existingRequests = approvalRequestData || [];
    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록

    // console.log("studentsToApprove", studentsToApprove);
    // console.log("existingRequests", existingRequests);

    studentsToApprove.forEach((student) => {
      const lowerRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage <= approverStage &&
          request.status === ("inProgress" || "finalized")
      );

      if (lowerRequest) {
        studentsToExclude.push(student.fullname);
        return;
      }

      // console.log("lowerRequest", lowerRequest);

      const higherStageApproved = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage > approverStage + 1 &&
          request.status !== "canceled"
      );

      // console.log("higherStageApproved", higherStageApproved);

      if (higherStageApproved) {
        studentsToExclude.push(student.fullname);
        return;
      }

      // console.log("existingRequests", existingRequests);
      // console.log("student", student);
      const existingRequest = existingRequests.find(
        (request) =>
          request.target_username === student.username &&
          request.stage === approverStage + 1 &&
          (request.status === "inProgress" || request.status === "finalized")
      );

      //이게 있는데 왜 못 가져옴
      // console.log("ddd", "inProgress" || "finalized");

      // console.log("approverStage", approverStage);

      // console.log("existingRequest", existingRequest);

      if (existingRequest) {
        existingRequest.status =
          approverStage === 1 ? "canceled" : "inProgress";
        existingRequest.stage = approverStage;
        existingRequest.release_date = releaseDate;
        existingRequest.approver_stage = approverStage;
        existingRequest.approver_username = user.username;
        existingRequest.action = "cancel";
        requestsToUpdate.push(existingRequest);
        studentsToProcess.push(student.fullname); // 취소할 학생 목록에 추가
      }
    });

    // console.log("studentsToExclude", studentsToExclude);
    // console.log("studentsToProcess", studentsToProcess);

    if (studentsToProcess.length === 0) {
      handleToastCenterTop("취소할 학생이 없습니다.");
      return;
    }

    if (studentsToExclude.length > 0) {
      const excludeMessage = `다음 학생들은 취소 단계가 맞지 않거나, 더 취소 할 수 없어서 제외되었습니다:\n- ${studentsToExclude.join(
        ", "
      )}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = requestsToUpdate;

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setCanceledWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setCanceledWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  /* 

  const handleCancel = () => {
    const studentsToApprove = studentListForApproval.filter((student) =>
      checkedStudents.includes(student.username)
    );

    const approverStageList = approvalLines.filter(
      (obj) => obj.username === user.username
    );

    let approverStage;
    if (approverStageList.length > 1) {
      const stageOptions = approverStageList.map((obj) => obj.stage).join(", ");
      const selectedStage = prompt(
        `권한이 여러 개입니다. 어떤 권한을 선택하시겠습니까? 가능한 단계: ${stageOptions}`
      );
      approverStage = approverStageList.find(
        (obj) => obj.stage.toString() === selectedStage
      )?.stage;
    } else {
      approverStage = approverStageList[0]?.stage;
    }

    if (!approverStage) {
      alert("올바른 권한 단계를 선택해주세요.");
      return;
    }

    const studentsToExclude = []; // 이미 결재된 학생 목록
    const studentsToProcess = []; // 결재할 학생 목록
    const requestsToUpdate = []; // 업데이트할 요청 목록

    studentsToApprove.forEach((student) => {
      const existingRequest = approvalRequestData.find(
        (request) =>
          request.target_username === student.username &&
          (request.stage === approverStage + 1 ||
            (request.status === "finalized" && request.stage === approverStage))
      );

      if (!existingRequest) {
        studentsToExclude.push(student.fullname);
      } else {
        existingRequest.status =
          approverStage === 1 ? "canceled" : "inProgress";
        existingRequest.stage = approverStage;
        existingRequest.release_date = releaseDate;
        existingRequest.approver_stage = approverStage;
        existingRequest.approver_username = user.username;
        existingRequest.action = "cancel";
        requestsToUpdate.push(existingRequest);
        studentsToProcess.push(student.fullname); // 취소할 학생 목록에 추가      }
      }
    });

    if (studentsToProcess.length === 0) {
      handleToastCenterTop("취소할 학생이 없습니다.");
      return;
    }

    // console.log("studentsToExclude", studentsToExclude);
    if (studentsToExclude.length > 0) {
      const excludeMessage = `다음 학생들은 취소 단계가 맞지 않거나, 더 취소 할 수 없어서 제외되었습니다.:\n- ${studentsToExclude.join(
        ", "
      )}`;
      handleToastCenterTop(excludeMessage, 5000);
    }

    const requestsToSave = requestsToUpdate;

    setIsLoadingModal(true);
    createOrUpdateApprovalRequestsBulk.mutate(requestsToSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setCanceledWell(true);
        handleExtraFunction();
        setTimeout(() => {
          setCanceledWell(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error(
          "위와 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요."
        );
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

*/

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "release_date") {
      setReleaseDate(value);
    }
  };

  const handleCheckboxChange = (username) => {
    setCheckedStudents((prev) =>
      prev.includes(username)
        ? prev.filter((name) => name !== username)
        : [...prev, username]
    );
  };

  const handleAllCheckboxChange = () => {
    if (isAllChecked) {
      setCheckedStudents([]);
    } else {
      const allStudentUsernames = studentListForApproval.map(
        (student) => student.username
      );
      setCheckedStudents(allStudentUsernames);
    }
    setIsAllChecked(!isAllChecked);
  };

  const getApprovalStatus = (username, stage) => {
    const action = approvalRequestData?.find(
      (action) => action.target_username === username
    );
    if (!action) {
      return "";
    }
    if (action.stage > stage || action.status === "finalized") {
      return "결재";
    } else {
      return "";
    }
  };

  if (!isModalOpen) return null;

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <h4>결재문서: 학기성적표</h4>
          <button className={styles.closeButton} onClick={handleCloseModal}>
            <MdClose />
          </button>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.infoSection}>
            <div className={styles.infoRow}>
              <div className={styles.label}>일자:</div>
              <div>
                <input
                  type="date"
                  value={currentDate.toLocaleDateString("sv-SE")}
                  onChange={(e) => setCurrentDate(new Date(e.target.value))}
                  readOnly
                />
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>제목:</div>
              <div className={styles.value}>
                <input type="text" value={requestTitle} readOnly />
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>결재그룹:</div>
              <div className={styles.value}>
                {approvalLines?.length > 0 &&
                  approvalLines.map((member, index) => (
                    <button
                      className="btn btn-sm btn-outline-dark pt-0 pb-0 p-1 ms-2 me-2"
                      key={index + "-" + member.stage}
                      style={{
                        "--bs-btn-font-size": ".80rem",
                      }}
                    >
                      {member.stage}단계:
                      {member.fullname}
                    </button>
                  ))}
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>대상:</div>
              <div className={styles.value}>
                {studentListForApproval?.map((r) => r.fullname + " ")}
              </div>
            </div>
            <div className={styles.infoRow}>
              <div className={styles.label}>발행일:</div>
              <div>
                <input
                  type="date"
                  name="release_date"
                  value={documentData?.currentPeriod?.end_date}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={styles.tableContainer}>
            <table className={styles.approvalTable}>
              <thead>
                <tr>
                  <th className="text-center">
                    <div className={styles.checkboxContainer}>
                      전체
                      <input
                        type="checkbox"
                        checked={isAllChecked}
                        onChange={handleAllCheckboxChange}
                      />
                    </div>
                  </th>
                  <th>과정</th>
                  <th>학년</th>
                  <th>반</th>
                  <th>이름</th>
                  <th>아이디</th>
                  {approvalLines?.length > 0 &&
                    approvalLines.map((member, index) => (
                      <th key={member.fullname + "-" + index}>
                        {member.stage}단계
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {studentListForApproval?.map((student) => (
                  <tr key={student.id}>
                    <td>
                      <div className={styles.checkboxContainer}>
                        <input
                          name={student.username}
                          type="checkbox"
                          checked={checkedStudents.includes(student.username)}
                          onChange={() =>
                            handleCheckboxChange(student.username)
                          }
                        />
                      </div>
                    </td>
                    <td>{student[1]}</td>
                    <td>{student[2]}</td>
                    <td>{student[3]}</td>
                    <td>{student.fullname}</td>
                    <td>{student.username}</td>
                    {approvalLines?.length > 0 &&
                      approvalLines.map((member, index) => (
                        <td key={member.stage + "-" + index}>
                          {member.stage &&
                            getApprovalStatus(student.username, member.stage)}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
            <div></div>
          </div>
          <div className={styles.buttonGroup}>
            <button className="btn btn-primary" onClick={handleApprove}>
              {approvedWell ? "결재완료" : "결재"}
            </button>
            <button className="btn btn-warning" onClick={handleCancel}>
              {canceledWell ? "취소완료" : "결재취소"}
            </button>
            <button className="btn btn-secondary" onClick={handleCloseModal}>
              닫기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
