import React, { useEffect, useState } from "react";
import DropdownsToFilter from "./components/DropdownsToFilter";
import { useLocation } from "react-router-dom";
import AddAwardActivity from "./components/AddAwardActivity";
import { useAuthContext } from "../../context/AuthContext";
import AwardTable from "./components/AwardTable";
import useEducationalActivities from "../../hooks/useEducationalActivities";
import useOrganizationSetting from "../../hooks/useOrganizationSetting";
import SideMenu from "./components/SideMenu";
import CareerTable from "./components/CareerTable";
import useTagList from "../../hooks/useTagList";
import AddCareerActivity from "./components/AddCareerActivity";
import AddVoluntaryActivity from "./components/AddVoluntaryActivity";
import VoluntaryTable from "./components/VoluntaryTable";
import AddClubActivity from "./components/AddClubActivity";
import ClubTable from "./components/ClubTable";
import useUsers from "../../hooks/useUsers";

//이제 tag list만 하면 된다.

export default function CreateActivities() {
  const location = useLocation();
  const paths = location.pathname.split("/");
  // 배열의 두 번째 요소를 schoolId로 사용합니다.
  const schoolId = paths[1];
  //아래 조건으로 데이터를 불러오도록 합니다.
  const conditions = {
    schoolid: schoolId,
    entity_type: "educational_activities",
  };

  const [conditionsEduActivities, setConditionsEduActivities] = useState({
    schoolid: schoolId,
    activity_type_id: "",
  });

  const { user } = useAuthContext();

  const {
    usersQuery: { data: userData },
  } = useUsers({ schoolid: schoolId }, { role: "teacher", status: 1 });

  const typeConditions = { ...conditions, type: "true" };
  const {
    educationalActivitiesTypeQuery: { data: educationalActivitiesTypeData },
  } = useEducationalActivities(typeConditions);

  const {
    educationalActivitiesQuery: { isLoading, data: educationalActivitiesData },
  } = useEducationalActivities(conditionsEduActivities);

  const {
    settingQuery: { data: settingData },
  } = useOrganizationSetting(conditions);

  const {
    tagListByEntityQuery: { data: tagList },
  } = useTagList(conditions);

  const [rows, setRows] = useState([]);
  const [schoolYear, setSchoolYear] = useState(null);
  const [schoolYearId, setSchoolYearId] = useState("all");
  const [schoolStageId, setSchoolStageId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");

  const [activeMenu, setActiveMenu] = useState({
    activity_type: "",
    activity_type_id: "",
  });

  useEffect(() => {
    if (educationalActivitiesTypeData) {
      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: educationalActivitiesTypeData[0].id,
      }));
      setActiveMenu({
        activity_type: educationalActivitiesTypeData[0].activity_type,
        activity_type_id: educationalActivitiesTypeData[0].id,
      });
    }
  }, [educationalActivitiesTypeData]);

  // 서버로부터 가져온 데이터를 rows 상태에 저장
  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;
    let initialSchoolStageId = null;
    let initialUsername = null;
    let filteredData = [];

    if (educationalActivitiesData && settingData && settingData.length > 0) {
      const enrichedRows = educationalActivitiesData.map((activity) => {
        // settings 배열에서 activity의 hierarchy_one과 일치하는 id를 찾음
        const matchingSetting = settingData.find(
          (setting) =>
            setting.id.toString() === activity.hierarchy_one.toString()
        );

        // 찾은 setting이 있다면 group_name을 사용하고, 없다면 "통합"을 기본값으로 사용
        const groupName = matchingSetting ? matchingSetting.group_name : "통합";

        // activity 객체에 group_name을 추가하여 반환
        return {
          ...activity,
          schoolStage: groupName,
        };
      });

      if (schoolYear === null && user) {
        //초기 데이터를 학년도로 필터해서 제공해야 하기 때문에 오름차순 제일 마지막 걸로
        // console.log("settingData", settingData);
        const sortedData = settingData.sort(
          (a, b) => b.school_year - a.school_year
        );

        const initialData = sortedData[0];
        const hierarchyLevelOneFiltered = sortedData.filter(
          (r) => r.hierarchy_level.toString() === "1"
        );

        initialSchoolYear = initialData.school_year;
        initialSchoolYearId = initialData.id;
        initialSchoolStageId = hierarchyLevelOneFiltered[0].id;
        initialUsername = user.username;
        setSchoolYear(initialSchoolYear);
        setSchoolYearId(initialSchoolYearId);
        setSchoolStageId(initialSchoolStageId);
        // setSelectedUser(initialUsername);

        // console.log("schoolYearId", initialSchoolYear);
        // console.log("schoolStageId", initialSchoolYearId);
        // console.log("selectedUser", initialUsername);

        filteredData = enrichedRows.filter(
          (row) =>
            row.school_year_id.toString() === initialSchoolYearId.toString() &&
            row.creator_username === initialUsername
        );
        setRows(filteredData);
      } else {
        const filteredRows = filterData(enrichedRows, {
          schoolYearId,
          schoolStageId,
          selectedUser,
        });
        setRows(filteredRows);
        // console.log("schoolYearId", schoolYearId);
        // console.log("schoolStageId", schoolStageId);
        // console.log("selectedUser", selectedUser);

        // console.log("filteredRows", filteredRows);
      }
      // console.log("enrichedRows", enrichedRows);
      // console.log("filteredData", filteredData);
    }
  }, [
    educationalActivitiesData,
    schoolYearId,
    settingData,
    schoolYear,
    schoolStageId,
    selectedUser,
    user,
  ]);

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;

    if (name === "0") {
      setSchoolYear(selectedOptionText);
      setSchoolYearId(value);
      setSchoolStageId("0");
    }

    if (name === "1") {
      // console.log("value", value);
      setSchoolStageId(value);
    }

    if (name === "users") {
      setSelectedUser(value);
    }
  };

  const handleQueryCondition = (item) => {
    if (item?.id) {
      setConditionsEduActivities((prev) => ({
        ...prev,
        activity_type_id: item.id,
      }));
    }
  };

  function renderComponent() {
    switch (activeMenu.activity_type) {
      case "awards":
        return (
          <>
            <AddAwardActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
            />
            <AwardTable rows={rows} setRows={setRows} tagList={tagList} />
          </>
        );
      case "careers":
        return (
          <>
            <AddCareerActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
            />
            <CareerTable rows={rows} setRows={setRows} tagList={tagList} />
          </>
        );
      case "voluntary":
        return (
          <>
            <AddVoluntaryActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
            />
            <VoluntaryTable rows={rows} setRows={setRows} tagList={tagList} />
          </>
        );

      // 다른 것들과 차별점은 개설자(교사)를 선택할 수 있다는 것이다.
      case "clubs":
        return (
          <>
            <AddClubActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
              userData={userData}
            />
            <ClubTable
              rows={rows}
              setRows={setRows}
              tagList={tagList}
              userData={userData}
            />
          </>
        );
      default:
        return (
          <>
            <AddAwardActivity
              conditions={conditions}
              schoolYear={schoolYear}
              schoolYearId={schoolYearId}
              schoolStageId={schoolStageId}
              setSchoolStageId={setSchoolStageId}
              user={user}
              tagList={tagList}
            />
            <AwardTable rows={rows} setRows={setRows} tagList={tagList} />
          </>
        );
    }
  }

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="basicWidth">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-2">
            <SideMenu
              educationalActivitiesTypeData={educationalActivitiesTypeData}
              activeMenu={activeMenu}
              setActiveMenu={setActiveMenu}
              extraFunction={handleQueryCondition}
            />
          </div>
          <div className="col-12 col-md-10">
            <div className="row">
              <div className="col-8 offset-4">
                <DropdownsToFilter
                  schoolYear={schoolYear}
                  schoolYearId={schoolYearId}
                  schoolStageId={schoolStageId}
                  user={user}
                  settingData={settingData}
                  rows={rows}
                  selectedUser={selectedUser}
                  handleDropdownChange={handleDropdownChange}
                />
              </div>
            </div>
            {renderComponent()}
          </div>
        </div>
      </div>
    </div>
  );
}

// 필터링 함수
function filterData(data, { schoolYearId, schoolStageId, selectedUser }) {
  return data.filter((item) => {
    const matchesSchoolYearId =
      schoolYearId === "all" ||
      item.school_year_id.toString() === schoolYearId.toString();
    const matchesSchoolStageId =
      schoolStageId === "0" ||
      item.hierarchy_one.toString() === schoolStageId.toString();
    const matchesSelectedUser =
      selectedUser === "" || item.creator_username === selectedUser;

    return matchesSchoolYearId && matchesSchoolStageId && matchesSelectedUser;
  });
}
