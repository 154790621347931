import React, { useMemo } from "react";
import {
  createFilteredAndSortedOptions,
  createFilteredAndSortedUniqueOptions,
} from "../../../util/dealingArrObj";

export default function DropdownsToFilter({
  schoolYear,
  schoolYearId,
  schoolStageId,
  handleDropdownChange,
  user,
  settingData,
  rows,
  selectedUser,
}) {
  const { arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc } = {
    arrayData: settingData,
    displayKey: "school_year",
    valueKey: "id",
    filters: { hierarchy_level: 0, parent_id: 0 },
    idKey: "id",
    sortKey: "school_year",
    isDesc: true,
  };

  const options = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey,
      valueKey,
      filters,
      idKey,
      sortKey,
      isDesc,
    });
  }, [arrayData, displayKey, valueKey, filters, idKey, sortKey, isDesc]);

  const optionsOne = useMemo(() => {
    return createFilteredAndSortedOptions({
      arrayData,
      displayKey: "group_name",
      valueKey,
      filters: { hierarchy_level: 1, school_year: schoolYear },
      idKey,
      sortKey: "order",
      isDesc: false,
      dataName: "organization_path",
    });
  }, [arrayData, valueKey, idKey, schoolYear]);

  const optionsUser = useMemo(() => {
    return createFilteredAndSortedUniqueOptions({
      arrayData: rows,
      displayKey: "fullname",
      valueKey: "creator_username",
      filters: { school_year: schoolYear },
      uniqueKey: "creator_username",
      sortKey: "fullname",
      isDesc: false,
    });
  }, [rows, schoolYear]);

  return (
    <div className="d-flex gap-2 align-items-center">
      <select
        className="form-control"
        name="0"
        onChange={handleDropdownChange}
        value={schoolYearId || ""}
      >
        {settingData?.length > 0 && options}
        <option value="all">전체</option>
      </select>
      <select
        className="form-control"
        name="1"
        onChange={handleDropdownChange}
        value={schoolStageId || ""}
      >
        {settingData?.length > 0 && optionsOne}
        <option value="0">통합</option>
      </select>
      <select
        className="form-control"
        name="users"
        onChange={handleDropdownChange}
        value={selectedUser || ""}
      >
        <option value="">교사</option>
        {/* <option value={user?.username}>{user?.fullname}</option> */}
        {rows?.length > 0 && optionsUser}
      </select>
    </div>
  );
}
