import React, { useEffect, useState } from "react";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";
import DropdownSchoolYear from "../../../unicomponents/Dropdowns/DropdownSchoolYear";
import { useUIContext } from "../../../context/UIContext";
import useVariousStudentRecords from "../../../hooks/useVariousStudentRecords";
import {
  findDuplicateData,
  generateRegistrationRows,
  trimObjectValues,
} from "../../../util/dealingArrObj";
import { calculateTextNumber } from "../../../util/etCetera";
import DropdownHierarchyOne from "../../../unicomponents/Dropdowns/DropdownHierarchyOne";
import DropdownHierarchyTwo from "../../../unicomponents/Dropdowns/DropdownHierarchyTwo";
import DropdownHierarchyThree from "../../../unicomponents/Dropdowns/DropdownHierarchyThree";
import DropdownUniqueUsersInDisplayData from "../../../unicomponents/Dropdowns/DropdownUniqueUsersInDisplayData";
import useRegistrationInfo from "../../../hooks/useRegistrationInfo";
import AddInputsRow from "../../../unicomponents/GeneratedByData/AddInputsRow";
import SaveInputsTable from "../../../unicomponents/GeneratedByData/SaveInputsTable";

/*
상단에 재적 dropdown + user의 수업 dropdown
하단에 기록할 내용 (various_record_inputs 에서 가져옴)
그 하단에 테이블로 저장한 것이 나옴 (various_record_inputs 에서 가져옴)
수정, 삭제 가능
*/

/*
@@ 1. dropdown의 과정, 학년, 반 선택에 따라서 filter 되어서 테이블 나오기
@@ 2. table에 group 추가 하기
@@ 3. 추가 만들기 (중복체크)
@@ 4. 저장 만들기
@@ 5. 삭제 만들기
*/

export default function EleReadingRecord({
  settingData,
  inputData,
  schoolId,
  inputQueryConditions,
}) {
  const [filters, setFilters] = useState({
    schoolYear: null,
    schoolYearId: "",
    hierarchyOneId: "",
    hierarchyTwoId: "",
    hierarchyThreeId: "",
    filters: "",
    organPathForSort: "N/A",
    selectedStudent: "",
    subjectName: "공통",
  });

  const [queryConditions, setQueryConditions] = useState({
    schoolid: schoolId,
    school_year_id: "",
    record_list_id: inputQueryConditions?.record_list_id,
  });

  const [dataToCreate, setDataToCreate] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [validateInput, setValidateInput] = useState({});
  const [dataToSave, setDataToSave] = useState([]);
  const [savedRows, setSavedRows] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState([]);

  const {
    registrationInfoQuery: { data: registrationInfoData },
  } = useRegistrationInfo({ schoolid: schoolId });

  const {
    getVariousStudentDataWithRegi,
    createOrUpdateVariousStudentRecords,
    deleteVariousStudentRecordOne,
  } = useVariousStudentRecords(queryConditions);

  const { setIsLoadingModal, handleToastCenterTop } = useUIContext();

  useEffect(() => {
    let initialSchoolYear = null;
    let initialSchoolYearId = null;

    if (
      filters.schoolYear === null &&
      registrationInfoData &&
      settingData &&
      settingData.length > 0
    ) {
      const initialData = settingData.sort(
        (a, b) => b.school_year - a.school_year
      )[0];

      initialSchoolYear = initialData.school_year;
      initialSchoolYearId = initialData.id;
      setFilters((filters) => ({
        ...filters,
        schoolYear: initialSchoolYear,
        schoolYearId: initialSchoolYearId,
      }));

      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: initialSchoolYearId,
        school_year: initialSchoolYear,
      }));

      //학생 리스트
      if (initialSchoolYear) {
        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          initialSchoolYear,
          filters.organPathForSort
        );
        // console.log("sortedRows", sortedRows);
        setStudentList(sortedRows);
      }
    } else {
      //rows 데이터처리
      if (registrationInfoData && settingData) {
        // console.log("registrationInfoData", registrationInfoData);
        // console.log("filters", filters);

        const sortedRows = generateRegistrationRows(
          registrationInfoData,
          settingData,
          filters.schoolYear,
          filters.organPathForSort
        );
        // console.log("sortedRows2", sortedRows);
        setStudentList(sortedRows);
      }
    }
  }, [
    filters,
    filters.organPathForSort,
    filters.schoolYear,
    registrationInfoData,
    settingData,
  ]);

  function queryVariousStudentDataWithRegi(forWhere, organPath = "") {
    getVariousStudentDataWithRegi.mutate(forWhere, {
      onSuccess: (data) => {
        if (settingData) {
          const settingMap = settingData.reduce((map, setting) => {
            map[setting.id] = setting.group_name;
            return map;
          }, {});

          const updatedData = data.map((studentData) => {
            const pathParts = studentData.organization_path.split("/");
            const stageId = pathParts[2]; // 과정
            const yearId = pathParts[3]; // 학년
            const classId = pathParts[4]; // 반

            const groupNameOne = settingMap[stageId];
            const groupNameTwo = settingMap[yearId];
            const groupNameThree = settingMap[classId];

            return {
              ...studentData,
              hierarchyOne: groupNameOne,
              hierarchyTwo: groupNameTwo,
              hierarchyThree: groupNameThree,
            };
          });
          const filteredData = updatedData.filter((r) =>
            r.organization_path.startsWith(organPath)
          );

          setDataToSave(filteredData);
        }
      },
      onError: (error) => {
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
        return "";
      },
    });
  }

  const handleDropdownChange = (e) => {
    const { name, value } = e.target;
    const selectedOptionText = e.target.selectedOptions[0].text;
    const dataName = e.target.selectedOptions[0].getAttribute("data-name");
    let organPath = "";
    if (!dataName) {
      organPath = "N/A";
    } else {
      organPath = dataName + "/" + value;
    }

    // console.log("dataToCreate", dataToCreate);

    // console.log("organPath", organPath);
    if (name === "0") {
      setFilters((filters) => ({
        ...filters,
        schoolYear: selectedOptionText,
        schoolYearId: value,
        hierarchyOneId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));
      setQueryConditions((prev) => ({
        ...prev,
        school_year_id: value,
        school_year: selectedOptionText,
      }));

      setDataToSave([]);
    }

    if (name === "1") {
      setFilters((filters) => ({
        ...filters,
        hierarchyOneId: value,
        hierarchyTwoId: "",
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));
      setDataToSave([]);
    }

    if (name === "2") {
      setFilters((filters) => ({
        ...filters,
        hierarchyTwoId: value,
        hierarchyThreeId: "",
        organPathForSort: "N/A",
        selectedStudent: "",
      }));
      setDataToSave([]);
    }

    if (name === "3") {
      setFilters((filters) => ({
        ...filters,
        hierarchyThreeId: value,
        organPathForSort: organPath,
        selectedStudent: "",
      }));

      queryVariousStudentDataWithRegi(queryConditions, organPath);
    }

    if (name === "students") {
      setFilters((filters) => ({
        ...filters,
        selectedStudent: value,
      }));
    }
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    const checkTagName = e.target.tagName;

    setDataToCreate((pre) => ({ ...pre, [name]: value }));

    if (checkTagName === "TEXTAREA") {
      let toCalculate = "";
      if (name === "thoughts") {
        toCalculate = calculateTextNumber(value);
        setDataToCreate((prev) => ({
          ...prev,
          readingTextLength: toCalculate,
        }));
      }
    }
  };

  const handleCreateAction = (e) => {
    e.preventDefault();
    if (Object.keys(dataToCreate).length < 1) {
      handleToastCenterTop("저장할 내용이 없습니다.");
      return;
    }

    if (filters.selectedStudent === "") {
      handleToastCenterTop("학생을 선택하세요.");
      setValidateInput((prev) => ({ ...prev, user: true }));
      return;
    } else {
      setValidateInput((prev) => ({ ...prev, user: false }));
    }

    const trimmedData = trimObjectValues(dataToCreate);

    const toSave = {
      schoolid: schoolId,
      school_year_id: filters.schoolYearId,
      record_list_id: inputQueryConditions?.record_list_id,
      group: filters.subjectName,
      username: filters.selectedStudent,
      value_object: trimmedData,
    };

    const keys = [
      "schoolid",
      "school_year_id",
      "record_list_id",
      "group",
      "username",
    ];
    const isDuplicate = findDuplicateData(dataToSave, toSave, keys);

    if (isDuplicate) {
      handleToastCenterTop(
        "당해년도의 분류와 학생이 같은 데이터가 이미 존재합니다!"
      );
      return;
    } else {
      console.log("중복된 데이터가 없습니다.");
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(toSave, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);

        queryVariousStudentDataWithRegi(
          queryConditions,
          filters.organPathForSort
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  const handleSaveState = (e, username, record_id) => {
    let value = e.target.value;
    let name = e.target.name;
    const checkTagName = e.target.tagName;

    setDataToSave((prevData) => {
      const existingItem = prevData.find((item) => item.id === record_id);

      if (existingItem) {
        let updatedValueObject = {
          ...existingItem.value_object,
          [name]: value,
        };

        if (checkTagName === "TEXTAREA") {
          let toCalculate = "";
          if (name === "thoughts") {
            toCalculate = calculateTextNumber(value);
            updatedValueObject.readingTextLength = toCalculate;
          }
        }

        return prevData.map((item) =>
          item.id === record_id
            ? {
                ...item,
                value_object: updatedValueObject,
                isModified: true,
              }
            : item
        );
      } else {
        let valueObject = { [name]: value };
        return [
          ...prevData,
          {
            schoolid: schoolId,
            school_year_id: filters.schoolYearId, // 적절한 기본값을 설정합니다.
            record_list_id: inputQueryConditions?.record_list_id,
            group: filters.group, // 적절한 기본값을 설정합니다.
            username,
            value_object: valueObject,
            isModified: true,
            allow_duplicate: false,
            // 기본값을 설정합니다. 필요에 따라 다른 기본값을 설정할 수 있습니다.
          },
        ];
      }
    });
  };

  const handleSaveClick = (row) => {
    // console.log("row", row);
    // console.log("dataToSave", dataToSave);

    const trimmedDataToSave = dataToSave.map((item) => {
      const trimmedValueObject = Object.fromEntries(
        Object.entries(item.value_object).map(([key, value]) => [
          key,
          typeof value === "string" ? value.trim() : value,
        ])
      );
      return { ...item, value_object: trimmedValueObject };
    });

    const toSave = trimmedDataToSave.filter((r) => r.id === row.id);

    // console.log("toSave.isModified", toSave.isModified);

    if (toSave.length < 1) {
      handleToastCenterTop("변경된 내용이 없습니다.");

      return;
    }

    if (!toSave[0].isModified) {
      // 변경사항이 없는 경우, 제출을 건너뜁니다.
      console.log("건너뛰기");
      handleToastCenterTop("변경된 내용이 없습니다.");
      return;
    }

    setIsLoadingModal(true);
    createOrUpdateVariousStudentRecords.mutate(toSave[0], {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setSavedRows((prev) => [...prev, toSave[0].id]);
        setTimeout(() => {
          setSavedRows([]);
        }, 1000);

        // 성공적으로 제출 후, 해당 데이터 세트의 isModified 상태를 초기화합니다.
        setDataToSave((currentDataSets) =>
          currentDataSets.map((dataSet) =>
            dataSet.id === toSave[0].id
              ? { ...dataSet, isModified: false }
              : dataSet
          )
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  // 삭제 관련
  const handleConfirmDelete = (row) => {
    setConfirmDelete((prev) => [...prev, row.id]);
  };

  const handleCancelDelete = () => {
    setConfirmDelete([]);
  };

  const handleDelete = (row) => {
    const id = row.id;
    setIsLoadingModal(true);
    deleteVariousStudentRecordOne.mutate(id, {
      onSuccess: (successMessage) => {
        console.log(successMessage);
        setIsLoadingModal(false);
        setConfirmDelete([]);
        queryVariousStudentDataWithRegi(
          queryConditions,
          filters.organPathForSort
        );
      },
      onError: (error) => {
        setIsLoadingModal(false);
        setConfirmDelete([]);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error.message
        );
      },
    });
  };

  return (
    <>
      <div className="row mt-3">
        <div className="col-auto">
          <h3 className="fw-bold">
            독서활동기록
            <HelpHyperLink link={"https://youtu.be/zqPZn5vI4EE"} />
          </h3>
        </div>
        <div className="col">
          <DropdownSchoolYear
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYearId={filters.schoolYearId}
            allYears={false}
          />
        </div>
        <div className="col">
          <DropdownHierarchyOne
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyTwo
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyOneId={filters.hierarchyOneId}
            hierarchyTwoId={filters.hierarchyTwoId}
          />
        </div>
        <div className="col">
          <DropdownHierarchyThree
            settingData={settingData}
            handleDropdownChange={handleDropdownChange}
            schoolYear={filters.schoolYear}
            hierarchyTwoId={filters.hierarchyTwoId}
            hierarchyThreeId={filters.hierarchyThreeId}
          />
        </div>
        <div className="col">
          <DropdownUniqueUsersInDisplayData
            displayData={studentList}
            displayKey={"fullname"}
            valueKey={"username"}
            filters={{ organization_path: filters.organPathForSort }}
            uniqueKey={"username"}
            sortKey={"studentno"}
            isDesc={false}
            handleDropdownChange={handleDropdownChange}
            selectedUser={filters.selectedStudent}
            selectName={"students"}
            styleClass={"form-control"}
            dropdownTitle={"학생"}
            required={true}
            highLight={validateInput?.user}
          />
          <input
            readOnly
            type="text"
            className="input-like-span p-1"
            value={filters.selectedStudent || ""}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-auto">
          <AddInputsRow
            inputData={inputData}
            handleCreateInputChange={handleCreateInputChange}
            handleCreateAction={handleCreateAction}
            dataToCreate={dataToCreate}
            styleClass="form-control"
            btnStyleClass="btn btn-primary"
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col" style={{ minWidth: "800px", overflowX: "auto" }}>
          <SaveInputsTable
            organSettingInfo={filters}
            inputData={inputData}
            userInputDataList={dataToSave}
            handleSaveState={handleSaveState}
            handleSaveClick={handleSaveClick}
            handleDelete={handleDelete}
            handleConfirmDelete={handleConfirmDelete}
            handleCancelDelete={handleCancelDelete}
            confirmDelete={confirmDelete}
            savedRows={savedRows}
            addfontSize="+2"
            addMinWidth=""
          />
        </div>
      </div>
    </>
  );
}
