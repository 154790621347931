import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import useEducationalActivities from "../../../hooks/useEducationalActivities";
import { useUIContext } from "../../../context/UIContext";
import HelpHyperLink from "../../../unicomponents/HelpHyperLink";

export default function AddAwardActivity({
  conditions,
  schoolYearId,
  schoolStageId,
  user,
  tagList,
}) {
  //input 할 때 업데이트 할 상태
  const [formData, setFormData] = useState({
    schoolid: conditions.schoolid,
    school_year_id: schoolYearId,
    hierarchy_one: "0",
    title: "",
    content: "",
    start_date: "",
    creator_username: user?.username,
    activity_type_id: 1,
    extra_attributes: { institute: "", participants: "" },
    tags: [],
  });

  //submit 누르면 로딩 뜨도록
  const { setIsLoadingModal } = useUIContext();

  //submit를 위한
  const { createEducationalActivities } = useEducationalActivities(conditions);

  const [tag, setTag] = useState("");
  const [isSaved, setIsSaved] = useState(false);

  const handleInputChange = (event) => {
    const { name, value, id } = event.target;

    console.log("user input change", user);

    if (name === "extra_attributes") {
      setFormData((formData) => ({
        ...formData,
        [name]: {
          ...formData[name],
          [id]: value,
        },
      }));
    } else if (name === "tags") {
      setTag(value);
      const newTag = { [value]: "3" };
      const isTagSelected = formData.tags.some((tag) =>
        tag.hasOwnProperty(value)
      );
      if (!isTagSelected && value !== "") {
        setFormData((formData) => ({
          ...formData,
          tags: [...formData.tags, newTag], // 새 태그 객체를 배열에 추가
        }));
      }
    } else {
      setFormData((formData) => ({
        ...formData,
        [name]: value,
      }));
    }

    // console.log("handle Input formData", formData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Process the formData here
    console.log("user submit", user);
    const submitData = {
      ...formData,
      school_year_id: schoolYearId,
      hierarchy_one: schoolStageId,
      creator_username: user?.username,
    };

    // console.log("submitData", submitData);
    //이제 여기서 서버로 보내면 됨

    setIsLoadingModal(true);
    createEducationalActivities.mutate(submitData, {
      onSuccess: () => {
        // console.log("formData2", submitData);
        //이제 여기서 서버로 보내면 됨

        setIsLoadingModal(false);
        setIsSaved(true);
        setTimeout(() => {
          setIsSaved(false);
        }, 2000);
      },
      onError: (error) => {
        setIsLoadingModal(false);
        console.error(error); // 일단 콘솔에 에러를 출력합니다.
        console.error("위와 같은 에러가 났으니 고치삼");
        alert(
          "다음과 같은 에러가 발생했습니다. 이 화면을 찍어 관리자에게 문의하세요. " +
            error?.message
        );
      },
    });
  };

  const handleClick = (tagKey) => {
    setFormData((formData) => ({
      ...formData,
      tags: formData.tags.filter((tag) => !tag.hasOwnProperty(tagKey)),
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <h3 className="fw-bold">
        시상활동 생성 <HelpHyperLink link={"https://youtu.be/eeLMjA4R4QE"} />
      </h3>
      <table className="table">
        <thead>
          <tr className="table-secondary">
            <th>수상명</th>
            <th>수상연월일</th>
            <th>수여기관</th>
            <th>참가대상</th>
            <th>개설자</th>
            <th>태그선택</th>
            <th>태그</th>
            <th>추가</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                className="input-like-span"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                required
              />
            </td>
            <td>
              <input
                type="date"
                className="input-like-span"
                name="start_date"
                value={formData.start_date}
                onChange={handleInputChange}
                required
              />
            </td>
            <td>
              <input
                type="text"
                className="input-like-span"
                name="extra_attributes"
                id="institute"
                value={formData.extra_attributes?.institute}
                onChange={handleInputChange}
                required
              />
            </td>
            <td>
              <input
                type="text"
                className="input-like-span"
                name="extra_attributes"
                id="participants"
                value={formData.extra_attributes.participants}
                onChange={handleInputChange}
                required
              />
            </td>
            <td width={80}>{user?.fullname}</td>
            <td>
              <select
                className="select-like-span"
                name="tags"
                value={tag}
                onChange={handleInputChange}
              >
                <option value="">선택</option>
                {tagList &&
                  tagList[0]?.tags.map((tag, index) => (
                    <option key={index} value={tag}>
                      {tag}
                    </option>
                  ))}
              </select>
            </td>
            <td width={128}>
              {formData.tags.map((tagObj, index) => {
                const [tagKey] = Object.keys(tagObj); // 객체에서 키 추출
                return (
                  <span key={index}>
                    {tagKey}
                    <MdOutlineCancel
                      role="button"
                      onClick={() => {
                        handleClick(tagKey); // 태그 삭제 함수 호출
                      }}
                    />
                  </span>
                );
              })}
            </td>
            <td width={60} rowSpan={2} valign="middle">
              <button type="submit" className="btn btn-sm p-1 btn-primary">
                {isSaved ? "완료" : "추가"}
              </button>
            </td>
          </tr>
          <tr>
            <td valign="middle" align="center">
              내용
            </td>
            <td colSpan={6}>
              <textarea
                type="text"
                className="form-control"
                name="content"
                value={formData.content}
                onChange={handleInputChange}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
}
